import styles from "../../styles/RestaurantTemplate.module.css";
import { useRouter } from "next/dist/client/router";
import { lang } from "../../i18n/lang";

import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { ReactNode, useEffect } from "react";
import { XyzTransition } from "@animxyz/react";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import MapboxMap from "../organisms/Map";

interface TemplateProps {
  name: string;
  bgSrc: string;
  links: {
    [name: string]: string;
    menu: string;
    order: string;
    reserve: string;
    ig: string;
    whatsapp: string;
    expReview: string;
  };
}

const SocialLink = ({
  children,
  href,
}: {
  children: ReactNode;
  href: string;
}) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={href}
      className={`${styles.link} 
        uppercase text-white font-medium p-2 md:p-3 md:text-xl lg:text-3xl 
        rounded-full flex items-center justify-center
        duration-200 transition-all motion-reduce:transition-none 
        motion-reduce:transform-none
      `}
    >
      {children}
    </a>
  );
};

export const Restaurant: React.FC<TemplateProps> = ({
  name,
  links,
  bgSrc,
}: TemplateProps) => {
  const router = useRouter();
  const local: string = router.locale || "es-ES";

  // lottie must be called client side
  useEffect(() => {
    import("@lottiefiles/lottie-player");
  }, []);

  console.log(name);

  return (
    <div
      className={`flex flex-col md:grid md:grid-cols-2 justify-center items-center`}
      style={{ backgroundImage: `url(${bgSrc})` }}
    >
      <div className={`${styles.container} container mx-auto flex flex-col items-center lg:items-start justify-center pt-40 pl-5 pr-2 xl:pl-40 xl:pr-0 lg:pl-6 xl:pt-10`}>
        <div className={styles.presentationOne} aria-hidden="true" />
        <div className={styles.presentationTwo} aria-hidden="true" />
        <XyzTransition duration="auto" appear xyz="fade top ease-in">
          <a
            className="no-underline"
            href="https://www.amauzgroup.com"
            rel="noreferrer noopener"
          >
            <h1 className="cursor-pointer z-10 fixed top-28 md:top-30 left-12 md:left-20 capitalize">
              {name}
            </h1>
          </a>
        </XyzTransition>
        <div className="flex flex-col z-10 gap-12">
          <div>
            <XyzTransition duration="auto" appear xyz="fade small">
              <div className="z-10 flex justify-end text-2xl md:text-3xl">
                <a
                  className="no-underline"
                  href="https://www.amauzgroup.com"
                  rel="noreferrer noopener"
                >
                  <FAI
                    icon={faHome}
                    width={30}
                    className="cursor-pointer mb-5 text-gray-200 hover:text-white transition-auto"
                  />
                </a>
              </div>
            </XyzTransition>
            <XyzTransition duration="auto" appear>
              <XyzTransition
                appear
                duration="auto"
                xyz="fade small stagger ease-out-back"
              >
                <div
                  className={`${styles.linksContainer} md:ml-10 flex justify-start items-start flex-col bg-gray-100 p-8 md:p-12`}
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={links.menu}
                    className={`${styles.link} uppercase text-white xyz-nested text-md font-normal xl:text-xl w-full p-4`}
                  >
                    {lang[local as string].restaurant[name].links.menu}
                  </a>
                  <a
                    target="_blank"
                    href={links.order}
                    rel="noreferrer"
                    className={`${styles.link} uppercase text-white xyz-nested text-md font-normal xl:text-xl w-full p-4`}
                  >
                    {lang[local as string].restaurant[name].links.order}
                  </a>
                  {lang[local as string].restaurant[name].links.reserve ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={links.reserve}
                      className={`${styles.link} uppercase text-white xyz-nested text-md font-normal xl:text-xl w-full p-4`}
                    >
                      {lang[local as string].restaurant[name].links.reserve}
                    </a>
                  ) : null}
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={links.expReview}
                    className={`${styles.link} uppercase text-white xyz-nested text-md font-normal xl:text-xl w-full p-4`}
                  >
                    {lang[local as string].restaurant[name].links.expReview}
                  </a>
                </div>
              </XyzTransition>
            </XyzTransition>
          </div>
          <div
            className="flex flex-nowrap gap-2 justify-start md:justify-end xl:justify-start items-start"
            style={{ maxWidth: 320 }}
          >
            <SocialLink href={links.ig}>
              <FAI icon={faInstagram} height="20" />
            </SocialLink>
            <SocialLink href={links.whatsapp}>
              <FAI icon={faWhatsapp} height="20" />
            </SocialLink>
            {/* @ts-ignore */}
            <SocialLink href={`mailto:${links.contact.email}`}>
              <FAI icon={faEnvelope} height="20" />
            </SocialLink>
            {/* @ts-ignore */}
            <SocialLink href={`tel:${links.contact.number}`}>
              <FAI icon={faPhoneAlt} height="20" />
            </SocialLink>
          </div>
        </div>
      </div>
      <div className="lg:mt-12 w-full z-20">
        <MapboxMap />
      </div>
    </div>
  );
};
