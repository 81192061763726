import type { NextPage } from "next";
import Head from "next/head";
import { Restaurant as RestaurantTemplate } from "../components/templates/Restaurant";

const Home: NextPage = () => {
  const links = {
    menu: "https://drive.google.com/file/d/1_lT_kZ88VVrxsR-c3-ObfpfnSFefvQKS/view?usp=sharing", 
    order: "https://bit.ly/38eXmza",
    reserve: "http://bitl.la/ReservasAmauz",
    ig: "https://www.instagram.com/amauzrestaurante/",
    whatsapp: "https://bit.ly/AmauzWhatsapp",
    expReview: "https://forms.gle/X6MZDm6wxN6hpvcz8",
    contact: {
      number: "+576048153892",
      email: "amauz@amauzgroup.com",
    },
  };

  const schemaData = {
    "@context": "http://www.schema.org",
    "@type": "Restaurant",
    name: "Amauzgroup",
    url: "https://amauz.amauzgroup.com",
    description:
      "Comienza esta historia de sabores, experiencias, elegancia y tradición",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Cra. 103 ## 97 A 28, Apartadó, Antioquia",
      addressLocality: "Apartado",
      addressRegion: "Antioquia",
      postalCode: "057841",
      addressCountry: "Colombia",
    },
    hasMap:
      "https://www.google.com/maps/place/AMAUZ+Restaurante/@7.8825814,-76.6364867,17z/data=!3m1!4b1!4m5!3m4!1s0x8e500d7b118cb6dd:0x71841f355d477c31!8m2!3d7.8825814!4d-76.634298",
    openingHours:
      "Mo 12:00-15:00 Tu 12:00-15:00 We 12:00-15:00 Th 12:00-15:00 Fr 12:00-15:00 Sa 12:00-15:00 Su 18:00-22:00",
    telephone: "6048153892",
  };
  return (
    <div>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="name" content="Amauz Restaurante" />
        <meta
          name="keywords"
          content="restaurantes en apartadó, cocina internacional, restaurante amauz restaurante, amauz restaurante"
        />
        <meta
          name="description"
          content="En Amauz Restaurante queremos que usted viva una experiencia que va mas allá de lo gastronómico; un momento para evocar la diversidad de una Cocina Internacional."
        />
        <meta name="author" content="Amauzgroup" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          id="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, viewport-fit=cover"
        />
        <meta name="theme-color" content="#323232" />

        {/** FACEBOOK */}
        <meta property="og:url" content="https://amauz.amauzgroup.com" />
        <meta property="og:site_name" content="Amauz Restaurant" />
        <meta property="og:title" content="Amauz Restaurante" />
        <meta
          property="og:description"
          content="En AMAUZ lo más importante es ofrecer una experiencia verdaderamente única utilizando en la mayoría de sus preparaciones productos locales y reinventando algunas recetas tradicionales."
        />

        {/** TWITTER */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="Amauz Restaurante Exquisitamente Elegante"
        />
        <meta
          name="twitter:description"
          content="En Amauz Restaurante queremos que usted viva una experiencia que va mas allá de lo gastronómico; un momento para evocar la diversidad de una Cocina Internacional."
        />
        <title>Amauz Restaurante</title>
        {/** Schema ld+json */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </Head>
      <RestaurantTemplate
        bgSrc={"/amauz-1.png"}
        name={"amauz"}
        /** @ts-ignore */
        links={links}
      />
    </div>
  );
};

export default Home;
